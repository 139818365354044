import Link from 'next/link';
import { useState } from 'react';

import { SubNav } from '@root/sanity/schema-types';
import IconTriangleDown from '@root/src/components/IconTriangleDown';
import AnimateHeight from '@root/src/components/AnimateHeight';
import HoverNav from '@root/src/partials/nav/HoverNav';

type NavItemProps = {
  isMobile?: boolean;
  item: SubNav;
  pathname: string;
  setIsVisible: (isVisible: boolean) => void;
} & ClassNameProp;

export const navItemClassNames = `
  flex
  items-center
  justify-between
  py-4
  px-16
  relative
  text-gray-darker
  text-h5-display
  whitespace-nowrap
`;
export const desktopNavItemClassNames = `
  flex
  hover:bg-gray-light
  hover:no-underline
  items-center
  md:flex
  md:h-[100px]
  md:items-center
  no-underline
  normal-case
  whitespace-nowrap
`;

export type NavItemType = 'Main' | 'Sub';

const urlSegmentsMatch = (a: string, b: string) => {
  if (!a || !b) {
    return false;
  }

  return a === b;
};

export const isActiveNavLink = (pathname: string, href: string, navItemType: NavItemType = 'Main') => {
  const urlPathSegments = pathname.split('/').filter(Boolean);
  const hrefPathSegments = href.split('/').filter(Boolean);

  const [mainUrlPathSegment, subUrlPathSegment] = urlPathSegments;
  const [mainNavItemSegment, subNavItemSegment] = hrefPathSegments;

  return navItemType === 'Sub'
    ? pathname === href || urlSegmentsMatch(subNavItemSegment ?? '', subUrlPathSegment ?? '')
    : urlSegmentsMatch(mainNavItemSegment ?? '', mainUrlPathSegment ?? '');
};

const NavItem = ({
  item: { href, trackerClass, subNavSectionBuilder, title },
  isMobile,
  pathname,
  setIsVisible,
}: NavItemProps) => {
  const [expand, setExpand] = useState<boolean>(false);

  const toggleExpand = () => {
    isMobile && setExpand((prev) => !prev);
    setIsVisible(false);
  };

  return (
    <div className={`group pointer-events-auto py-12 md:py-0 ${isMobile ? 'border-b-1 border-gray' : ''}`}>
      {subNavSectionBuilder?.length && isMobile ? (
        <div
          onClick={() => setExpand((prev) => !prev)}
          className={`md:h-80 ${navItemClassNames} ${
            isActiveNavLink(pathname, href) ? 'text-blue-dark' : 'text-gray-darker'
          } ${trackerClass ?? ''}`}
        >
          <span>{title}</span>
          <IconTriangleDown className={`md:hidden ${expand ? '' : 'rotate-90'}`} />
        </div>
      ) : (
        <Link href={href}>
          <a
            onClick={() => setIsVisible(false)}
            className={`md:h-100 ${navItemClassNames} ${!isMobile ? desktopNavItemClassNames : ''} ${
              isActiveNavLink(pathname, href) && 'text-blue-dark'
            } ${trackerClass ?? ''}`}
          >
            <span>{title}</span>
          </a>
        </Link>
      )}

      <AnimateHeight open={expand} className={'md:hidden'}>
        <div className={'mt-8 mb-2 ml-32 flex flex-col space-y-[10px]'}>
          {subNavSectionBuilder?.map((subNavItem) => (
            <div key={`${subNavItem.title}-${subNavItem.href}`}>
              <Link href={subNavItem.href}>
                <a
                  className={`flex text-base md:hover:underline ${
                    isActiveNavLink(pathname, subNavItem.href, 'Sub') ? 'text-black' : 'text-gray-dark'
                  } ${subNavItem.trackerClass ?? ''}`}
                  onClick={toggleExpand}
                >
                  {subNavItem.title}
                </a>
              </Link>
            </div>
          ))}
        </div>
      </AnimateHeight>
      {subNavSectionBuilder && <HoverNav navItems={subNavSectionBuilder} />}
    </div>
  );
};

export default NavItem;
