import React, { ReactNode } from 'react';
import Link from 'next/link';
import IconArrowRight from 'components/IconArrowRight';

type SalesCalloutProps = {
  calloutId: string;
  children: ReactNode;
  className?: string;
  ctaPageName: string;
  ctaTitle?: string;
  href: string;
  image: ReactNode;
  title: string;
};

const SalesCallout = ({
  calloutId,
  children,
  className,
  ctaPageName,
  ctaTitle,
  href,
  image,
  title,
}: SalesCalloutProps) => {
  return (
    <Link href={href}>
      <a
        className={`group flex w-full cursor-pointer flex-col justify-between bg-white transition-all duration-200 ease-in-out hover:bg-white hover:shadow-3 ${
          className ? className : ''
        } tracker-callout-${ctaPageName}-${calloutId}-20190408-093833`}
      >
        <div data-testid="sailscallout-image">{image}</div>

        <div className="h-full flex-grow bg-black p-16 pt-16 lg:p-32 lg:pt-16">
          <h3
            className="text-h4 mb-8 text-white lg:mb-16"
            data-testid="salescallout-title"
            style={{ willChange: 'color' }}
          >
            {title}
          </h3>

          <p
            className="hyphens text-sm break-words text-white transition-all duration-200 ease-in-out group-hover:text-blue-dark"
            data-testid="salescallout-content"
          >
            {children}
          </p>
        </div>

        <hr className="border-gray-darker" />

        {ctaTitle && (
          <div
            className="flex items-center justify-end space-x-8 bg-black p-16 transition-all duration-200 ease-in-out"
            style={{ willChange: 'color' }}
          >
            <IconArrowRight className="!text-blue-dark" />
            <div className="text-h6 text-blue-dark" data-testid="salescallout-cta-title">
              {ctaTitle}
            </div>
          </div>
        )}
      </a>
    </Link>
  );
};

export default SalesCallout;
