import React, { ReactNode } from 'react';
import { urlFor } from '@root/lib/sanity';
import IconListRow from './IconListRow';
import { IconListRow as IconListRowProps } from '@root/sanity/schema-types';

type IconListProps = {
  heading: ReactNode;
  button: ReactNode;
  className?: string;
  iconListItems: any;
};

type SanityIconListRowProps = IconListRowProps & { src?: string };

const IconList = ({ className, iconListItems, heading, button }: IconListProps) => {
  return (
    <div className={`container ${className ?? ''}`}>
      <div className="pt-32">
        <div className="container bg-panel-9/12-to-left ">
          <div className="row">
            <div className="col-start-5 col-end-10 space-y-32">
              <h2 className="text-h1 -mt-32">{heading}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-32 bg-panel-9/12-to-left py-32">
        {iconListItems.map((item: SanityIconListRowProps, index: number) => {
          const srcStr = item.img?.asset ? urlFor(item.img.asset).url() : item?.src ?? '';
          const alt = item.img?.alt ?? '';
          const numberStr = String(index + 1).padStart(2, '0');

          return (
            <IconListRow
              key={index}
              src={srcStr}
              alt={alt}
              number={numberStr}
              headline={item.headline}
              body={item.body}
            />
          );
        })}
      </div>

      <div className="p-section bg-panel-9/12-to-left pt-0">
        <div className="row">
          <div className="col-start-5 col-end-13">{button}</div>
        </div>
      </div>

      {/** Need this so FAQ section can overlap with this. */}
      <div className="container -mb-128 bg-panel-9/12-to-left pt-128" />
    </div>
  );
};

export default IconList;
